import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ScaleIcon from '@mui/icons-material/Scale';

import Header from "./common/Header2";
import IntroDescriptionPage from "../snippets/IntroDescriptionPage";
import ContactPersons from "../widgets/ContactPersons";
import Usefull from "../snippets/Usefull";



const images = [
  'Slide1HeroCompare'
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};


function SeatContent(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	
  	return (<>
			  	<Header heroImages={images} label={t("Sitzebänke")} slogan={t("Welche Sitzbank soll es den sein?")}  breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}
				>

	  				<Box  sx={{paddingTop: "0px", marginTop: "0px"}} > 
Sitzbänke Page			      																		
					</Box>

	  				<Box sx={{paddingTop: "40px"}}> 
						<ContactPersons/>
					</Box>
   			</Box>
   		</>
   	)
}

export default function SeatPage(props) {
	
  const postData = useLocation();
  console.log("POST DATA:", postData.state);	
	
  return <SeatContent props={props} />;
}