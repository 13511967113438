import React from 'react';
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import ReadMoreIcon from '@mui/icons-material/ReadMore';

import Header from "./common/Header";
import ContactPersons from "../widgets/ContactPersons";
import FeatureProducts from "../widgets/FeatureProducts";
import AboutCamping from "../widgets/AboutCamping";

import Newsletter from '../snippets/Newsletter';
import Advice from '../snippets/Advice';

import ReimoAtTheNews from "../snippets/ReimoAtTheNews";
import Compare from "../snippets/Compare";
import OurOffer from "../snippets/OurOffer";
import CarCard from "../widgets/CarCard";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import 'animate.css';

import models from '../db/models.json';

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  	...theme.typography.body2,
  	padding: theme.spacing(1),
  	textAlign: 'center',
  	color: theme.palette.text.secondary,
}));

function StartContent(props) {
 	const [displayNewsletter, setDisplayNewsletter] = React.useState(false);

  	let navigate = useNavigate(); 

	const { t, i18n } = useTranslation("",{lng: props.language});

	const callUrl = (url) => {
		navigate(url);
	};
	
	const [ref0, inView0] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	
	const [ref1, inView1] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	
	const [ref2, inView2] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});	
	
	const [ref3, inView3] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	

  	return (<>
	  			<Box>
					<Header embedded={props.props.embedded}/>

	  			</Box>
				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  							
							} }}
				>

					
					
			      		<Grid container style={{paddingTop: "40px"}}>
				        		<Grid item xs={12}>
						      		<Grid container spacing={2}>
						        		<Grid item xs={0} sm={0} md={1} lg={1} xl={2}>
							     	   </Grid>
						        		<Grid item xs={12} sm={12} md={10} lg={10} xl={8}>
											<Box sx={{	border: "2px solid black", 
														padding: "10px", 
														borderRadius: "5px", 
														display: { 	xs: 'none',
																	sm: 'block',  
																	md: 'block',  
																	lg: 'block',  
																	xl: 'block',  																		
																}
													}}
												>
												<ReactCompareSlider portrait={false}
												  ref={{
												    current: '[Circular]'
												  }}
												  boundsPadding={0}
												  itemOne={<ReactCompareSliderImage alt="Image one" src="/assets/basecars/sliderinnen.jpg"/>}
												  itemTwo={<ReactCompareSliderImage alt="Image two" src="/assets/basecars/slideraussen.jpg" style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
												  keyboardIncrement="5%"
												  position={50}
												  style={{
												    height: '60vh',
												    width: '100%'
												  }}
												  transition=".75s ease-in-out"
												/>
											</Box>
											<Box sx={{	border: "2px solid black", 
														padding: "10px", 
														borderRadius: "5px", display: {
																		xs: 'block',
																		sm: 'none',  
																		md: 'none',  
																		lg: 'none',  
																		xl: 'none',  
																		
																		}}}
												>
												<ReactCompareSlider portrait={false}
												  ref={{
												    current: '[Circular]'
												  }}
												  boundsPadding={0}
												  itemOne={<ReactCompareSliderImage alt="Image one" src="/assets/basecars/sliderinnen.jpg"/>}
												  itemTwo={<ReactCompareSliderImage alt="Image two" src="/assets/basecars/slideraussen.jpg" style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
												  keyboardIncrement="5%"
												  position={50}
												  style={{
												    height: '30vh',
												    width: '100%'
												  }}
												  transition=".75s ease-in-out"
												/>
											</Box>
							     	   </Grid>
						        		<Grid item xs={0} sm={0} md={1} lg={1} xl={2}>
							     	   </Grid>
						     	   </Grid>
					     	   </Grid>
			     	   	</Grid>	
					<ReimoAtTheNews/>
	
					<Compare/>
	
					<br/><br/><br/>
					<Advice />
	
	
		  				<Box sx={{paddingTop: "40px", paddingTop: "40px", display: {
																		xs: 'none',
																		sm: 'none',  
																		md: 'block',  
																		lg: 'block',  
																		xl: 'block',  																		
																		}}}> 
							<Box sx={{paddingTop: "0px"}}>
								<Box class="highlightContainer" sx={{	textAlign: "center", 
																		color: "#000"}}>					
									<center>				
										<h2 class="highlightText headlineNewPoint">{t("OurCars")}</h2>
										<h3 class="highlightText sloganNewPoint">{t("ExploreOurModels")}</h3>
									</center>				
									<Swiper
									        slidesPerView={3}
									        spaceBetween={30}
									        freeMode={true}
									        pagination={{
									          clickable: true,
									        }}
									        modules={[FreeMode, Pagination]}
									        className="carsSwiper"
									        style={{minHeight: "550px", backgroundColor: "hsla(0,0%,94.9%,.5)"}}
									      >
											{models.cars.map((model) => (
												<>
													{model.onlinestatus == "online" ? (
											        	<SwiperSlide>
															<CarCard model={model}/>																						
											        	</SwiperSlide>
														):(<></>)
													}
									        	</>
									        ))}
									</Swiper>								
								</Box>			
							</Box>	
		  				
		  				
							<span ref={ref3} />
	
	        				{inView3 === true && displayNewsletter == true ? (<Newsletter open={true} handleNewsletterDisplay={setDisplayNewsletter}/>) : (<></>)}
							

						</Box>
	
		  				<Box sx={{paddingTop: "40px", paddingTop: "40px", display: {
																		xs: 'block',
																		sm: 'block',  
																		md: 'none',  
																		lg: 'none',  
																		xl: 'none',  																		
																		}}}> 
							<Box sx={{paddingTop: "0px"}}>
								<Box class="highlightContainer" sx={{	textAlign: "center", 
																		color: "#000"}}>	
									<center>				
									<h2 class="highlightText headlineNewPoint">{t("OurCars")}</h2>
									<h3 class="highlightText sloganNewPoint">{t("ExploreOurModels")}</h3>
									</center>				

									<Swiper
									        slidesPerView={1}
									        spaceBetween={30}
									        freeMode={true}
									        pagination={{
									          clickable: true,
									        }}
									        modules={[FreeMode, Pagination]}
									        className="carsSwiper"
									        style={{minHeight: "550px", backgroundColor: "hsla(0,0%,94.9%,.5)"}}
									      >
											{models.cars.map((model) => (
												<>
													{model.onlinestatus == "online" ? (
											        	<SwiperSlide>
															<CarCard model={model}/>																						
											        	</SwiperSlide>
														):(<></>)
													}
									        	</>
									        ))}
									</Swiper>								
								</Box>			
							</Box>	
		  				
		  				
							<span ref={ref3} />
	
	        				{inView3 === true && displayNewsletter == true ? (<Newsletter open={true} handleNewsletterDisplay={setDisplayNewsletter}/>) : (<></>)}
							

						</Box>
	


			      		<Grid container sx={{paddingTop: "0px", display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							}}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={1}>
									</Grid>
					        		<Grid item xs={10}>
							      		<Grid container >
							        		<Grid item xs={12}>
									      		<Grid container spacing={2}>
									        		<Grid item xs={6}>
									  					<FeatureProducts title="Schlafdächer" slogan="gehört einfach dazu" image="/assets/items/roof.jpg"/>
													</Grid>
									        		<Grid item xs={6}>
									  					<FeatureProducts title="Sitzbänke" slogan="sicherer Schutz" image="/assets/items/sitzbank.jpg"/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
					        		<Grid item xs={1}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						
						  				
		  				
		  				<Box sx={{paddingTop: "40px"}}> 
							<Box class="fixBG">					
								<Box sx={{paddingTop: "50px",paddingBottom: "30px", textAlign: "center"}}>				
								<h2 class="highlightText headlineNewPoint" style={{color: "#dfdfdf"}}>{t("Customersays")}</h2>
							</Box>
							<Grid container style={{paddingBottom: "20px"}}>
				        		<Grid item xs={12}>
						      		<Grid container spacing={2}>
						        		<Grid item xs={2}>
							     	   </Grid>
						        		<Grid item xs={8}>
								      		<Grid container >
								        		<Grid item xs={12}>
										      		<Grid container spacing={2}>
										        		<Grid item xs={12}>
										        		
															<Swiper
																breakpoints={{
																    // when window width is >= 640px
																    640: {
																      width: 640,
																      slidesPerView: 1,
																    },
																    // when window width is >= 768px
																    768: {
																      width: 768,
																      slidesPerView: 2,
																    },
																    1024: {
																      width: 1024,
																      slidesPerView: 3,
																    },
																  }}															        
				  													spaceBetween={30}
															        freeMode={true}
															        pagination={{
															          clickable: true,
															        }}
															        modules={[FreeMode, Pagination]}
															        className="carsSwiper1"
															        style={{minHeight: "550px"}}
															      >
														        	<SwiperSlide>
																		<figure class="snip1390" style={{backgroundColor: "rgba(220,220,220,.7)", padding: "10px", borderRadius: "5px", width: "80%"}}>
																			<center>
																			  <img src="assets/profile-silhouette.jpeg" alt="profile-sample3" class="profile" />
																			</center>
																		  <figcaption>
																		  
																		    <h2>Susi Mustermann</h2>
																		    <h4>Camping Hubra</h4>
																		    <blockquote>Tolle Firma, schnelle und guter Service</blockquote>
																		  </figcaption>
																		</figure>
														        	</SwiperSlide>
														        	<SwiperSlide>
																		<figure class="snip1390" style={{backgroundColor: "rgba(220,220,220,.7)", padding: "10px", borderRadius: "5px", width: "80%"}}>
																			<center>
																			  <img src="assets/profile-silhouette.jpeg" alt="profile-sample3" class="profile" />
																			</center>
																		  <figcaption>
																		  
																		    <h2>Susi Mustermann</h2>
																		    <h4>Camping Hubra</h4>
																		    <blockquote>Tolle Firma, schnelle und guter Service</blockquote>
																		  </figcaption>
																		</figure>
														        	</SwiperSlide>
														        	<SwiperSlide>
																		<figure class="snip1390" style={{backgroundColor: "rgba(220,220,220,.7)", padding: "10px", borderRadius: "5px", width: "80%"}}>
																			<center>
																			  <img src="assets/profile-silhouette.jpeg" alt="profile-sample3" class="profile" />
																			</center>
																		  <figcaption>
																		  
																		    <h2>Susi Mustermann</h2>
																		    <h4>Camping Hubra</h4>
																		    <blockquote>Tolle Firma, schnelle und guter Service</blockquote>
																		  </figcaption>
																		</figure>
														        	</SwiperSlide>
														        	<SwiperSlide>
																		<figure class="snip1390" style={{backgroundColor: "rgba(220,220,220,.7)", padding: "10px", borderRadius: "5px", width: "80%"}}>
																			<center>
																			  <img src="assets/profile-silhouette.jpeg" alt="profile-sample3" class="profile" />
																			</center>
																		  <figcaption>
																		  
																		    <h2>Susi Mustermann</h2>
																		    <h4>Camping Hubra</h4>
																		    <blockquote>Tolle Firma, schnelle und guter Service</blockquote>
																		  </figcaption>
																		</figure>
														        	</SwiperSlide>
															</Swiper>								
										        		

											     	   </Grid>
										     	   </Grid>
									     	   </Grid>
								     	   </Grid>
							     	   </Grid>
						        		<Grid item xs={2}>
							     	   </Grid>
						     	   </Grid>
					     	   </Grid>
				     	   	</Grid>				
						</Box>
						
						<hr className="spacer"/>
		

		
		  				<Box sx={{paddingTop: "40px"}}> 
							<ContactPersons profil={props.props.profil}/>
						</Box>
	
	   				</Box>
   			</Box>
   		</>
   	)
}

export default function StartPage(props) {

  return <StartContent props={props}/>;
}