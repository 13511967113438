import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Zoom } from 'react-slideshow-image';
import { useInView } from "react-intersection-observer";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import TopDrawer from './TopDrawer';
import TopContent from './TopContent';
import LatestNews from './LatestNews';
import LatestNewsSmall from './LatestNewsSmall';

import 'react-medium-image-zoom/dist/styles.css'
import 'react-slideshow-image/dist/styles.css'


const buttonStyle = {
    width: "50px",
    height: "160px",
    background: 'none',
    border: '0px',
    marginLeft: "10px",
    marginRight: "20px"
};

const spanStyle = {
 	padding: '20px',
  	color: '#000000'
}

const divStyle = {
  	display: 'flex',
  	alignItems: 'center',
  	justifyContent: 'center',
 	backgroundSize: 'cover',
  	height: '100vH'
}

const divStyleSmall = {
  	display: 'flex',
  	alignItems: 'center',
  	justifyContent: 'center',
 	backgroundSize: 'cover',
  	height: '50vH'
}

const properties = {
    prevArrow: <button style={{ ...buttonStyle }}><ArrowBackIosIcon sx={{width: "3em", height: "3em", fill: "#fff", zIndex: 3000}}/></button>,
    nextArrow: <button style={{ ...buttonStyle }}><ArrowForwardIosIcon sx={{width: "3em", height: "3em", fill: "#fff", zIndex: 3000}}/></button>
}

const SlideshowSmall = () => {
	const { t, i18n } = useTranslation();

	const images = [ {
	  	"src": "Slide1HeroStartPage",
		"headline": t("Ford Triostyle"),
		"slogan": t("Entdecken Sie den Ford Camper für sich"),
		"buttonlabel": t("jetztentdecken"),
		"href": "/model/ford-triostyle/"
		},{
	  	"src": "Slide2HeroStartPage",
		"headline": "Volkswagen Caddy Weekender2",
		"slogan": t("Kleinercamptkeiner"),
		"buttonlabel": t("jetztentdecken"),
		"href": "/model/caddy-weekender2/"
		},{
	  	"src": "Slide3HeroStartPage",
		"headline": "Volkswagen TrioStyle",
		"slogan": t("Exklusive Camping-Vollausstattung"),
		"buttonlabel": t("informieren"),
		"href": "/model/vw-triostyle/"
		}
	];	
	const [anim, setAnim] = React.useState("galleryImgZoom");
	const [anim1, setAnim1] = React.useState("animate__animated animate__backInDown");
	const [anim2, setAnim2] = React.useState("animate__animated animate__rollIn");
	const [anim3, setAnim3] = React.useState("animate__animated animate__fadeInUp");
  	let navigate = useNavigate(); 
	
	const callUrl = (url) => {
		navigate(url);
	};

	const startChange = () => {
		setAnim("");
		setAnim1("animate__animated animate__fadeOutTopRight textOutline");
		setAnim2("animate__animated animate__fadeOutTopLeft textOutline");
		setAnim3("animate__animated animate__fadeOutDown textOutline");
	};

	const hasChanged = () => {
		setAnim("galleryImgZoom");
		setAnim1("animate__animated animate__backInDown");
		setAnim2("animate__animated animate__rollIn");
		setAnim3("animate__animated animate__fadeInUp");
	};

    return (
      <div className="slide-container" style={{height: "50vH"}}>
        <Zoom 	scale={0.2} 
        		easing="cubic-out" 
        		infinite={true} 
        		arrows={false}
        		{...properties} 
        		onStartChange={startChange} 
        		onChange={hasChanged}>
          {
			images.map((each, index) => (
				<>
					<Box className="heroAdvice" style={{marginTop1: "45vH"}}>
	                	<div style={{marginTop: "auto"}} >
							{anim1 == "" ? (<></>) : (
	                		<div >
								<center>
			                		<h2 class={anim1 + " heroHeadline"}><span style={{backgroundColor: "rgba(100,100,100,0.5)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "4px"}}>{each.headline}</span></h2>
									<h3 class={anim2 + " heroSlogan"}><span style={{backgroundColor: "rgba(100,100,100,0.5)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "4px"}}>{each.slogan}</span></h3>
									<Button className={anim3} onClick={e => callUrl(each.href)} variant="contained">{each.buttonlabel}</Button>                	
		                		</center>
	                		</div>
	                		)}
	                	</div>	
					</Box>
					<div key={index}>
	              		<Box class={each.src}  style={{ ...divStyleSmall }}>
  		               	</Box>
            		</div>				
				</>
			))
          }
        </Zoom>
      </div>
    )
}



const Slideshow = () => {
	const { t, i18n } = useTranslation();

	const images = [ {
	  	"src": "Slide1HeroStartPage",
		"headline": t("Ford Triostyle"),
		"slogan": t("Entdecken Sie den Ford Camper für sich"),
		"buttonlabel": t("jetztentdecken"),
		"href": "/model/ford-triostyle/"
		},{
	  	"src": "Slide2HeroStartPage",
		"headline": "Volkswagen Caddy Weekender2",
		"slogan": t("Kleinercamptkeiner"),
		"buttonlabel": t("jetztentdecken"),
		"href": "/model/caddy-weekender2/"
		},{
	  	"src": "Slide3HeroStartPage",
		"headline": "Volkswagen TrioStyle",
		"slogan": t("Exklusive Camping-Vollausstattung"),
		"buttonlabel": t("informieren"),
		"href": "/model/vw-triostyle/"
		}
	];	
	
	
	const [anim, setAnim] = React.useState("galleryImgZoom");
	const [anim1, setAnim1] = React.useState("animate__animated animate__backInDown");
	const [anim2, setAnim2] = React.useState("animate__animated animate__rollIn");
	const [anim3, setAnim3] = React.useState("animate__animated animate__fadeInUp");
  	let navigate = useNavigate(); 
	
	const callUrl = (url) => {
		navigate(url);
	};

	const startChange = () => {
		setAnim("");
		setAnim1("animate__animated animate__fadeOutTopRight textOutline");
		setAnim2("animate__animated animate__fadeOutTopLeft textOutline");
		setAnim3("animate__animated animate__fadeOutDown textOutline");
	};

	const hasChanged = () => {
		setAnim("galleryImgZoom");
		setAnim1("animate__animated animate__backInDown");
		setAnim2("animate__animated animate__rollIn");
		setAnim3("animate__animated animate__fadeInUp");
	};

    return (
      <div className="slide-container" style={{height: "100vH"}}>
        <Zoom 	scale={0.2} 
        		easing="cubic-out" 
        		infinite={true} 
        		arrows={false}
        		{...properties} 
        		onStartChange={startChange} 
        		onChange={hasChanged}>
          {
			images.map((each, index) => (
				<>
					<Box className="heroAdvice" style={{marginTop: "55vH"}}>
	                	<div style={{marginTop: "auto"}} >
							{anim1 == "" ? (<></>) : (
	                		<div >
								<center>
			                		<h2 class={anim1 + " heroHeadline"}><span style={{backgroundColor: "rgba(100,100,100,0.5)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "4px"}}>{each.headline}</span></h2>
									<h3 class={anim2 + " heroSlogan"}><span style={{backgroundColor: "rgba(100,100,100,0.5)", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "4px"}}>{each.slogan}</span></h3>
									<Button className={anim3} onClick={e => callUrl(each.href)} variant="contained">{each.buttonlabel}</Button>                	
		                		</center>
	                		</div>
	                		)}
	                	</div>	
					</Box>
					<div key={index}>
	              		<Box  class={each.src + " " + anim + " galleryImg "} style={{ ...divStyle }}>
  		               	</Box>
            		</div>				
				</>
			))
          }
        </Zoom>
      </div>
    )
}


export default function Header(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	const [page, setPage] = React.useState("home");


  	const change = (which) => {
		setPage(which);
	}
	
	const [ref, inView] = useInView({
	    triggerOnce: false,
	    rootMargin: '0px 0px',
	});
  	return (<>
				<Box className="heroAdvice" >
					<TopContent embedded={props.embedded}/>
					<hr class="spacerHeader" /><br/>
					<Box sx={{marginLeft: { xs: '10px',
											sm: '10px',  
											md: '60px',  
											lg: '60px',  
											xl: '60px',  							
								}, 
								display: {	xs: 'none',
											sm: 'block',  
											md: 'block',  
											lg: 'block',  
											xl: 'block',  
											
								}
							}}
					>
						<LatestNews/>
					</Box>
				</Box>
								
				<span ref={ref} />
					<Box  sx={{display: {	xs: 'none',
											sm: 'block',  
											md: 'block',  
											lg: 'block',  
											xl: 'block',  
											
								}
							}}
					>
						<Slideshow />
					</Box>
					<Box  sx={{display: {	xs: 'block',
											sm: 'none',  
											md: 'none',  
											lg: 'none',  
											xl: 'none',  
											
								}
							}}
					>
						<SlideshowSmall />
						<br/>
						<br/>
						<center>
							<LatestNewsSmall/>
						</center>

					</Box>

				<TopDrawer show={inView === true ? false : true} change={change} page={page} embedded={props.embedded}/>
			</>
	  )
}