import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import Box from '@mui/material/Box';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

import menu from '../../db/menue.json';
import models from '../../db/models.json';



export default function Menu(props) {
	let x = 1;
	const { t, i18n } = useTranslation("",{lng: props.language});

	const languages = [
	  { name: t("Deutsch") ,  iso: "de" , icon: <CheckIcon sx={{fontSize: '28px', color: 'white'}}/> },
	  { name: t("English"),  iso: "en" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Französich"),  iso: "fr" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Italienisch"),  iso: "it" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Spanisch"),  iso: "es" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Niederländisch"),  iso: "nl" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Polnisch"),  iso: "pl" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Dänisch"),  iso: "da" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Schwedisch"),  iso: "sv" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Finnisch"),  iso: "fi" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Norwegisch"),  iso: "nb" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Türkisch"),  iso: "tr" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Romänisch"),  iso: "ro" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Slowenisch"),  iso: "sl" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Schweiz"),  iso: "ch" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Russisch"),  iso: "ru" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Slowakisch"),  iso: "sk" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Estnisch"),  iso: "et" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Ungarisch"),  iso: "hu" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Tschechisch"),  iso: "cs" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: t("Litauisch"),  iso: "lt" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	];


  	let navigate = useNavigate(); 
	
	const callUrl = (url) => {
		navigate(url);
	};
	
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	};
	
  	return (<>
  			{props.embedded === "true" ? (<></>) :
  	(
				<Box className="navbar" sx={{paddingRight: {
							xs: 0,
							sm: 0,  
							} }}>
					<div class="dropdown">
						<button class="dropbtn">
							{t("Sprache")} <KeyboardArrowDownIcon/>
							<i class="fa fa-caret-down"></i>
						</button>
						<div class="dropdown-content">
							{languages.map((language) => (
								<a onClick={e => changeLanguage(language.iso)}>{language.iso == i18n.resolvedLanguage ? (<CheckIcon/>) : (<></>)}{t(language.name)}</a>
							))}
								<a onClick={e => changeLanguage("xx")}>{"xx" == i18n.resolvedLanguage ? (<CheckIcon/>) : (<></>)}Default</a>
							
						</div>
					</div>
				</Box>
				)}
			</>
	  )
}