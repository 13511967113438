import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PublishIcon from '@mui/icons-material/Publish';
import CheckIcon from '@mui/icons-material/Check';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LanguageIcon from '@mui/icons-material/Language';

import Toaster from './Toaster';

import {getTranslations, postTranslations, postLanguage} from "../../../helper/utils";

const initR = await getTranslations();


const columns = [
	{ field: 'id', headerName: 'ID', width: 90 },
  	{
    	field: 'key',
    	headerName: 'Schlüssel',
    	width: 300,
    	editable: false,
  	},
  	{
    	field: 'value',
    	headerName: 'Übersetzung',
    	width: 600,
    	editable: true,
  	},
];

	const languages = [
	  { name: "Deutsch" ,  iso: "de" , icon: <CheckIcon sx={{fontSize: '28px', color: 'white'}}/> },
	  { name: "English",  iso: "en" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Französich",  iso: "fr" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Italienisch",  iso: "it" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Spanisch",  iso: "es" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Niederländisch",  iso: "nl" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Polnisch",  iso: "pl" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Dänisch",  iso: "da" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Schwedisch",  iso: "sv" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Finnisch",  iso: "fi" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Norwegisch",  iso: "nb" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Türkisch",  iso: "tr" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Romänisch",  iso: "ro" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Slowenisch",  iso: "sl" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Schweiz",  iso: "ch" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Russisch",  iso: "ru" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Slowakisch",  iso: "sk" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Estnisch",  iso: "et" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Ungarisch",  iso: "hu" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Tschechisch",  iso: "cs" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	  { name: "Litauisch",  iso: "lt" , icon: <CheckIcon  sx={{fontSize: '28px', color: 'white'}}/>},
	];

export default function TranslationTable() {
	async function getTranslations() {
		const initR = await getTranslations();
		return initR;
	}	
	
	
	const [openMsg, setOpenMsg] = React.useState(false);
	const [toasterMsg, setToasterMsg] = React.useState("");	
  	const [iso, setIso] = React.useState('de');
  	const [isoDest, setIsoDest] = React.useState('all');
  	const [openBD, setOpenBD] = React.useState(false);
  	const [initRa, setInitRa] = React.useState(getTranslations());
  	const [type, setType] = React.useState("missing");
  	const [openChooseLng, setOpenChooseLng] = React.useState(false);
	const { t, i18n } = useTranslation("");

	const languages = [
	  { name: t("Deutsch") ,  iso: "de"  },
	  { name: t("English"),  iso: "en" },
	  { name: t("Französich"),  iso: "fr" },
	  { name: t("Italienisch"),  iso: "it"},
	  { name: t("Spanisch"),  iso: "es" },
	  { name: t("Niederländisch"),  iso: "nl"},
	  { name: t("Polnisch"),  iso: "pl"},
	  { name: t("Dänisch"),  iso: "da" },
	  { name: t("Schwedisch"),  iso: "sv"},
	  { name: t("Finnisch"),  iso: "fi" },
	  { name: t("Norwegisch"),  iso: "nb" },
	  { name: t("Türkisch"),  iso: "tr" },
	  { name: t("Romänisch"),  iso: "ro" },
	  { name: t("Slowenisch"),  iso: "sl" },
	  { name: t("Schweiz"),  iso: "ch" },
	  { name: t("Russisch"),  iso: "ru" },
	  { name: t("Slowakisch"),  iso: "sk" },
	  { name: t("Estnisch"),  iso: "et"},
	  { name: t("Ungarisch"),  iso: "hu" },
	  { name: t("Tschechisch"),  iso: "cs" },
	  { name: t("Litauisch"),  iso: "lt" },
	];

  
	let r = [];
	Object.keys(initR[iso].translation).forEach((key, index) => {
		let o = {};
		o.id = index;
		o.key = key;
		o.value = initR.de.translation[key];
		r.push(o);
	})
	
  	const [rows, setRows] = React.useState(r);
  	
  	const handleChange = (event) => {
    	setIso(event.target.value);

		let r1 = [];
		Object.keys(initR[event.target.value].translation).forEach((key, index) => {
			let o = {};
			o.id = index;
			o.key = key;
			o.value = initR[event.target.value].translation[key];
			r1.push(o);
		})
    	setRows(structuredClone(r1));
  	};
  	
  	const handleCloseChooseLng = () => {
    	setOpenChooseLng(false);
  	};
  	
  	const handleIsoDest = (event) => {
    	setIsoDest(event.target.value);
  	};
  	
  	const handleType = (event) => {
    	setType(event.target.value);
  	};

	async function publishTranslations() {	
		setOpenChooseLng(false);
		let x= {};
		x.input = rows;
    	setOpenBD(true);

		setOpenMsg(true);
		setToasterMsg("Publizierung gestartet");
	
		let data = await postTranslations(x, "missing");
    	setOpenBD(false);
	
		console.log(data);
	}; 

	async function publishLanguage() {	
		let x= {};
		x.input = rows;
    	setOpenBD(true);

		setOpenMsg(true);
		setToasterMsg("Publizierung gestartet");
	
		let data = await postLanguage(x, iso);
    	setOpenBD(false);
	
		console.log(x);
	}; 






	const handleProcessRowUpdate = (updatedRow, oldRow) => {
		initR.de.translation[updatedRow.key] = updatedRow.value; 
	  	const rowIndex = rows.findIndex((row) => row.id === updatedRow.id);
	  	const updatedRows = [...rows];
	  	updatedRows[rowIndex] = updatedRow;
	  	setRows(updatedRows);
	  	return updatedRow;
	}
	
	return (<>
				<Box sx={{ height: '60vH', width: '100%' }}>
					<DataGrid 	rows={rows}
	        					columns={columns}
								initialState={{
	          						pagination: {
	            						paginationModel: {
	              							pageSize: 20,
	            						},
	          						},
	        					}}
	        					processRowUpdate={handleProcessRowUpdate}
								pageSizeOptions={[20]}
	        					disableRowSelectionOnClick
	      			/>
	      			<hr/>
					<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
				    	<Typography gutterBottom variant="h6" component="div">
							<FormControl fullWidth>
								<InputLabel id="demo-simple-select-label">Sprache</InputLabel>
							  	<Select labelId="demo-simple-select-label"
							    		id="demo-simple-select"
							    		value={iso}
							    		label="Age"
							    	onChange={handleChange}
							  	>
								{languages.map((language) => (
									<MenuItem value={language.iso}>{language.name}</MenuItem>	
								))}
							  </Select>
							</FormControl>



		      			</Typography>
				        <Typography gutterBottom variant="h6" component="div">
				      		<Button onClick={e => setOpenChooseLng(true)} endIcon={<LanguageIcon />}>übersetzen</Button>	
				      		<Button onClick={publishLanguage} endIcon={<PublishIcon />}>Änderungen publizieren</Button>	
				       	</Typography>
				  	</Stack>      
	    		</Box>

				<Dialog open={openChooseLng}
        				onClose={handleCloseChooseLng}
        				
      			>
        			<DialogTitle>Auswahl</DialogTitle>
        			<DialogContent sx={{minWidth: "640px", minHeight: "320px"}}>
						<FormControl  sx={{margin: "20px", width: "90%"}}>
					        <InputLabel id="demo-simple-select-label">Sprache</InputLabel>
					        <Select
					          labelId="demo-simple-select-label"
					          id="demo-simple-select"
					          value={isoDest}
					          label="Sprache"
					          onChange={handleIsoDest}
					        >
					        	<MenuItem value="all">Alle Sprachen</MenuItem>
									{languages.map((language) => (
										<MenuItem value={language.iso}>{t(language.name)}</MenuItem>
									))}
					         

					        </Select>
						</FormControl>
						<FormControl  sx={{margin: "20px", width: "90%", marginTop: "10px"}}>
					        <InputLabel id="demo-simple-select-label">Modus</InputLabel>
					        <Select
					          labelId="demo-simple-select-label"
					          id="demo-simple-select"
					          value={type}
					          label="Modus"
					          onChange={handleType}
					        >
					          <MenuItem value="all">Alle Einträge neu übersetzen</MenuItem>
					          <MenuItem value="missing">Fehlende Einträge ergänzen</MenuItem>
					        </Select>
						</FormControl>
        			</DialogContent>
        			<DialogActions>
          				<Button onClick={handleCloseChooseLng}>Abbruch</Button>
          				<Button onClick={publishTranslations}>Übersetzen</Button>
        			</DialogActions>
      			</Dialog>	    		
	    		
	    		
				<Toaster open={openMsg} msg={toasterMsg} handleClose={setOpenMsg}/>
		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>  				
			</>
  );
}