import * as React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FeatureProducts(props) {
	  	let navigate = useNavigate(); 

	const [expanded, setExpanded] = React.useState(false);
	const { t, i18n } = useTranslation("",{lng: props.language});
	const callUrl = (url) => {
			navigate(url);
			window.scrollTo(0, 0);
	};
  	return (
	  <Card sx={{ maxWidth: 345 }}>
     	<CardHeader 
	        title={props.title}
	        subheader={props.slogan}
      	/>
		<div class="hover14 column">
			<div>
				<figure>
			    	<CardMedia 	component="img"
			        			height="194"
			        			image={props.image}
			        			alt={props.title}
			      	/>
		      	</figure>
		  	</div>
		</div>    
		      
      	<CardContent>
        	<Box  style={{textAlign: "justify", color: "#000"}}>
          		{props.description}
        	</Box>
      	</CardContent>
		<CardActions sx={{  alignSelf: "stretch",
					      	display: "flex",
					      	justifyContent: "flex-end",
					      	alignItems: "flex-start",
					      	pb: "20px",
		}}>
			<Button onClick={e => callUrl(props.url)} 
					variant="contained"
					endIcon=<InfoIcon/>
					>informieren
			</Button>			
		</CardActions>
		
    </Card>
  );
}