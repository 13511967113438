import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ScaleIcon from '@mui/icons-material/Scale';

import Header from "./common/Header2";
import IntroDescriptionPage from "../snippets/IntroDescriptionPage";
import ContactPersons from "../widgets/ContactPersons";
import Usefull from "../snippets/Usefull";



const images = [
  'Slide1HeroCompare'
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};


function RoofContent(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	
  	return (<>
			  	<Header heroImages={images} label={t("Schlafdächer")} slogan={t("Welches Dach soll es den sein?")}  breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}
				>

	  				<Box  sx={{paddingTop: "20px", marginTop: "0px"}} > 
						<IntroDescriptionPage description="REIMO bietet verschiedene Dachtypen für alle gängigen Modelle.
Unsere Fahrzeugdächer sind der beste Weg, neuen Platz im Auto zu schaffen.
Hier gibt es drei grundsätzlich verschiedene Möglichkeiten, wie Sie Ihr Fahrzeug nach oben erweitern können:
Schlafdach (Aufstelldach), Hochdach oder das kleine Hubdach."/>

			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={1}>
									</Grid>
					        		<Grid item xs={10}>
							      		<Grid container >
							        		<Grid item xs={12}>
									      		<Grid container spacing={2}>
									        		<Grid item xs={4}>
														<img src="/assets/addons/schlafdach.jpg" />	
														<Box  style={{textAlign: "justify", color: "#000", maxWidth: "400px"}}>
															<p>
															Erweitern Sie den verfügbaren Platz in Ihrem Reisemobil
															mit einem aufklappbaren Schlafdach 
															(wird oft auch als Aufstelldach oder einfach Klappdach bezeichnet).
															Der Vorteil ist die Flexibiltät - mit einem Reimo Superflach-Dach
															passt das Auto bei zugeklapptem Dach weiterhin in die Tiefgarage. Am Urlaubsort haben Sie im aufgeklappten Dach Platz für
															2 zusätzliche Schlafplätze. 
															Für Sicht und Lüftung ist gesorgt durch Fenster und Moskitonetze.
															</p>
															<p>
															Das Reimo Schlafdach bietet die Möglichkeit, ein zusätzliches Bett von ca. 110x190 cm (mit Schaumstoffmatratze 40 mm)
															in einem kompakten Fahrzeug einzurichten.
															Es ist schrägaufstellend und entweder vorne oder hinten aufstellbar. Je nach Fahrzeugtyp beträgt die erzielte Innenraumhöhe
															ca. 180-240 cm.
															</p>
															<p>
															Je nach Modell ist das Dach mit bis zu 3 Fenstern ausgestattet,
															teilweise mit Moskitonetzen, die für eine gute Belüftung sorgen.
															Auf den meisten Reimo Schlafdächern können Sie Lasten bis ca. 30 kg (Surfboard o.ä.) transportieren.
															Selbstverständlich können Sie es mit einer Solarzelle oder
															dem Glasdach "Sunroof" ausstatten.
															</p>
														</Box>
													</Grid>
									        		<Grid item xs={4}>
														<img src="/assets/addons/hochdach.jpg" />	
														<Box  style={{textAlign: "justify", color: "#000", maxWidth: "400px"}}>
															<p>
															Die Vorteile des fest installierten Hochdaches -
															es bietet noch mehr Platz und es ermöglicht eine Isolierung,
															ist also erste Wahl, wenn Sie ihr Reisemobil das ganze Jahr nutzen wollen
															oder Ihre bevorzugten Urlaubsziele in
															nördlichen Regionen liegen.
															Das Hochdach bietet vielfältige Möglichkeiten
															für den Einbau von Fenstern und Dachluken.
															</p>														
															<p>
															Das Reimo Hochdach hat eine feste GFK-Kunststoffschale,
bietet zusätzlichen Stau- und Wohnraum und
ist die Lösung für den Camper, der in nahezu jeder Jahreszeit
unterwegs sein will.
Die glasfaserverstärkte Kunststoffschale ist dafür auch bei schlechtem Wetter bestens geeignet, die aerodynamische Form hält den Benzinverbrauch im Rahmen.
Je nach Fahrzeugtyp kann der Dachausschnitt unterschiedlich groß (zwischen den Querholmen oder den Frontscheibe bis Heck) ausgeführt werden.
															</p>														
															<p>
Für einige Dächer bietet Reimo zusätzlich an:
GFK-Innenschale:
Damit haben Sie auf einfache und bequeme Weise eine glatte
und pflegeleichte Innenfläche.
Velours-Innenverkleidung:
Bestehend aus mit Veloursstoff bezogenen,
vorverklebten 20 mm Isolationsplatten.
In ein Reimo Hochdach können Dachhauben oder Fenster zur zusätzlichen Belüftung montiert werden.															
															</p>														
														</Box>
													</Grid>
									        		<Grid item xs={4}>
														<img src="/assets/addons/hubdach.jpg" />	
														<Box  style={{textAlign: "justify", color: "#000", maxWidth: "400px"}}>
															<p>
Mit einem preiswerten Hubdach erhöhen Sie die Stehhöhe in Ihrem Fahrzeug,
üblicherweise im Bereich der Schiebetür.
Dies ist nützlich, wenn Sie eine Kochstelle bequem nutzen wollen und sorgt für
mehr Komfort beim Ankleiden und mehr Kopffreiheit für die Sitzgruppe.
															</p>														
															<p>
Das Reimo Hubdach stellt eine preiswerte Alternative dar, wenn man nur im Küchen-/Anziehbereich Stehhöhe benötigt. Das Hubdach ist parallel aufzustellen.
Es erhöht den Innenraum um ca. 45 cm, wodruch für ca. 1 qm Fläche Stehhöhe ermöglicht wird.															
															</p>														
															<p>
Je nach Fahrzeugtyp trägt das Dach ca. 6-12 cm auf. Für gute Belüftung sorgen 3 mit Zellstoff verschließbare Fenster mit Moskitonetzeinsatz. Die Montage erfolgt üblicherweise im Bereich der Schiebetür.
															</p>														
														</Box>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
					        		<Grid item xs={1}>
									</Grid>
								</Grid>
							</Grid>



					</Box>

	  				<Box sx={{paddingTop: "40px"}}> 
						<ContactPersons/>
					</Box>
   			</Box>
   		</>
   	)
}

export default function RoofPage(props) {
	
  const postData = useLocation();
  console.log("POST DATA:", postData.state);	
	
  return <RoofContent props={props} />;
}