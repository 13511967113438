import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import Header from "./common/Header2";
import ContactPersons from "../widgets/ContactPersons";
import ModelFamilieTab from '../widgets/ModelFamilieTab';
import ActionCard from '../widgets/ActionCard';

import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SnowshoeingIcon from '@mui/icons-material/Snowshoeing';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import models from '../db/models.json';


function ModelContent(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	const getCar = (which) => {
		let car;
		models.cars.forEach((value, keys) => {
			if(value.url == which)
		  		car = value;
	  })
	  return car;
  	};

	var model = getCar(props.props.model);


  	const [ variant, setVariant] = React.useState("kr");

	var deep_value = function(obj, path){
	    for (var i=0, path=path.split('.'), len=path.length; i<len; i++){
	        obj = obj[path[i]];
	    };
	    return obj;
	};

  	const getValueByVariant = (value, path) => {
		try {
			model.variants.forEach((modelvariant, keys) => {		  
				if(modelvariant.variantID == variant)
					value = deep_value(modelvariant, path);		  
			})  
		}
		catch (error) {}		  
		return value;
  	};



  	return (<>
  	
			  	<Header heroImages1="Slide1HeroCompare" heroImages={model.heroImages} label={model.label} slogan={model.slogan} breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							} }}
				>

						<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={0}>
						     	   </Grid>
					        		<Grid item xs={4}>

								        <Typography variant="body2" color="text.secondary" sx={{fontSize: "1.0em"}}>
											{t("UVP")}<br/>{t("ab")} {Number(getValueByVariant(model.price, "price")).toLocaleString("de-DE", {minimumFractionDigits: 2}) + " €"}
								        </Typography>

						     	   </Grid>
					        		<Grid item xs={6}>

								        <Typography variant="body2" color="text.secondary" sx={{fontSize: "0.8em"}}>
											<ul class="empty" sx={{listStyleType: "none"}}>
												<li><AirlineSeatReclineNormalIcon sx={{fontSize: "1.5em"}}/>{model.seats} + {model.seatsOptional} {t("seats")}</li>
												<li><AirlineSeatFlatIcon sx={{fontSize: "1.5em"}}/>{model.sleepingplaces} + {model.sleepingplacesOptional} {t("sleepingPlaces")}</li>
												<li><ViewInArIcon sx={{fontSize: "1.5em"}}/>{getValueByVariant(model.size.outerLength, "size.outerLength")}cm x {getValueByVariant(model.size.outerHeight, "size.outerHeight")}cm x {getValueByVariant(model.size.outerWidth, "size.outerWidth")}cm</li>
											</ul>											        			
								        </Typography>
	
						     	   </Grid>
					        		<Grid item xs={0}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>



			      		<Grid container style={{paddingTop: "40px"}}>
				        		<Grid item xs={12}>
						      		<Grid container spacing={2}>
						        		<Grid item xs={0}>
							     	   </Grid>
						        		<Grid item xs={12}>
											<Box sx={{border: "2px solid black", padding: "10px", borderRadius: "5px"}}>
												<ReactCompareSlider
												  ref={{
												    current: '[Circular]'
												  }}
												  boundsPadding={0}
												  itemOne={<ReactCompareSliderImage alt="Image one" src={model.compareImage1}/>}
												  itemTwo={<ReactCompareSliderImage alt="Image two" src={model.compareImage2} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
												  keyboardIncrement="5%"
												  position={50}
												  style={{
												    height: '30vh',
												    width: '100%'
												  }}
												  transition=".75s ease-in-out"
												/>
											</Box>
							     	   </Grid>
						        		<Grid item xs={0}>
							     	   </Grid>
						     	   </Grid>
					     	   </Grid>
			     	   	</Grid>


						<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={0} sm={1}>
						     	   </Grid>
					        		<Grid item xs={12} sm={4}>
					        			{model.imageDay == "" ? (
					        				<>
					        				
												<Box sx={{border: "2px solid #eee", marginTop: "20px",padding: "10px", borderRadius: "0px"}}>
													
													<ReactCompareSlider
													  ref={{
													    current: '[Circular]'
													  }}
													  boundsPadding={0}
													  itemOne={<ReactCompareSliderImage alt="Image one" src={model.imageDay}/>}
													  itemTwo={<ReactCompareSliderImage alt="Image two" src={model.imageNight} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
													  keyboardIncrement="5%"
													  position={50}
				
													  transition=".75s ease-in-out"
													/>					        		
							        			</Box>
							        			<em>{t("Grundriss Tag / Nacht")}1111</em>	
						        			</>			        		
						        			) : (<></>)
					        			}
						     	   </Grid>
					        		<Grid item xs={12} sm={6}>
						  				<Box sx={{paddingTop: "0px"}}> 
											<ul class="check">
											{model.featurepoints.map((point) => (
												<li class="check">{point}</li>	
											))}
											</ul>
					   					</Box>
	
						     	   </Grid>
					        		<Grid item xs={0} sm={1}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>
			     	   	
			     	   	
						<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={0} sm={0}>
						     	   </Grid>
					        		<Grid item xs={12} sm={6}>
						        		<h3  class="highlightText sloganNewPoint">{t("Download")}</h3>
						        		
							     	   	{getCar(model.url).downloads.map((file) => (

						        			<Paper sx={{marginBottom: "10px"}}>

												<Card sx={{ minWidth: "90%",  border: "none", boxShadow: "none"}} className="centerCarCard">
													<Box sx={{width: "40px", height: "40px", float: "right"}}>
														<SnowshoeingIcon sx={{width: "2em", height: "2em"}}/>
													</Box>
									
									      			<CardHeader titleTypographyProps={{variant:'h6' }}
									        					title={file.label}
																subheader=""
									      			/>
									      			<CardContent>
												        <Typography variant="body2" color="text.secondary">
															tbd
												        </Typography>
													</CardContent>
													<CardActions sx={{  alignSelf: "stretch",
																	      display: "flex",
																	      justifyContent: "flex-end",
																	      alignItems: "flex-start",
																	      p: 0,
													}}>
														<Button variant="contained"
																target="new" 
																href={"/assets/downloads/"+ file.file}
																sx={{float: "right"}}
																endIcon={<DownloadIcon />}>
														  {t("Download")}
														</Button>
													</CardActions>
									    		</Card>

						        			</Paper>
										))}


						     	   </Grid>
					        		<Grid item xs={12} sm={5}>
										<h3  class="highlightText sloganNewPoint">{t("DoNextSteps")}</h3>
					        			<Paper sx={{marginBottom: "10px"}}>
					        				<ActionCard usp="1" header={t("InfoMaterialanfordern")} description={t("InfoMaterialanforderDescription")}  actionurl={"requestMaterial"} car={model.url}/>					        				
					        			</Paper>

					        			<Paper sx={{marginBottom: "10px"}}>
					        				<ActionCard usp="3" header={t("Terminvereinbaren")} description={t("TerminvereinbarenDescription")} actionurl={"requestDate"}  car={model.url}/>					        				
					        			</Paper>

					        			<Paper>
					        				<ActionCard usp="4" header={t("konfigurieren")} description={t("KonfigureDescription")} actionurl={"/configure/" + model.url}/>					        				
					        			</Paper>	
						     	   </Grid>
					        		<Grid item xs={0} sm={0}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>


		  				<Box sx={{paddingTop: "40px"}}> 
							<ContactPersons profil={props.props.profil}/>
						</Box>
				</Box>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}
				>

	  				<Box sx={{paddingTop: "0px", marginTop: "40px"}}> 
			      		<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={1}>
						     	   </Grid>
					        		<Grid item xs={5}>
										<Box sx={{paddingTop: "0px", backgroundColor: "#fff"}}>
											<Box  style={{color: "#000"}}>					
												{getCar(model.url).models.length > 0 ? (
													<FormControl variant="standard" sx={{ m: 1, minWidth: 120, fontSize: "18px",backgroundColor: "white", marginBottom: "30px" }}>
		                                                <InputLabel id="demo-simple-select-standard-label" sx={{fontSize: "20px"}}>{t("Version")}</InputLabel>
		                                                <Select labelId="demo-simple-select-standard-label"
													          	id="demo-simple-select-standard"
													          	sx={{fontSize: "20px" }}
													          	value={variant}
													          	onChange={e => setVariant(e.target.value)}
													 	>													        
															{getCar(model.url).models.map((variant) => (												        												    												        
														          	<MenuItem value={variant.id} sx={{fontSize: "20px" }}>{variant.label}</MenuItem>
														    ))}
													    </Select>
												      </FormControl>										
													) :(<></>)
												}
												<Box sx={{paddingLeft: "50px"}} >

											        <Typography variant="body2" color="text.secondary" sx={{fontSize: "1.5em"}}>
														{t("UVP")}<br/>{t("ab")} {Number(getValueByVariant(model.price, "price")).toLocaleString("de-DE", {minimumFractionDigits: 2}) + " €"}
											        </Typography>
											        
											        
											        <Typography variant="body2" color="text.secondary" sx={{fontSize: "1.2em"}}>
														<ul class="empty" sx={{listStyleType: "none"}}>
															<li><AirlineSeatReclineNormalIcon sx={{fontSize: "1.5em"}}/>{model.seats} + {model.seatsOptional} {t("seats")}</li>
															<li><AirlineSeatFlatIcon sx={{fontSize: "1.5em"}}/>{model.sleepingplaces} + {model.sleepingplacesOptional} {t("sleepingPlaces")}</li>
															<li><ViewInArIcon sx={{fontSize: "1.5em"}}/>{getValueByVariant(model.size.outerLength, "size.outerLength")}cm x {getValueByVariant(model.size.outerHeight, "size.outerHeight")}cm x {getValueByVariant(model.size.outerWidth, "size.outerWidth")}cm</li>
														</ul>											        			
											        </Typography>
												</Box>			
											</Box>			
										</Box>	
						     	   	</Grid>
					        		<Grid item xs={5} sx={{	display: "flex",
															flexDirection: "row",
															justifyContent: "end",
															alignItems: "flex-end"}}
									>
										<Box sx={{paddingTop: "0px", backgroundColor: "#fff"}} >
											<Box  style={{textAlign: "justify", color: "#000"}}>					
												<p class="altFliessText" style={{margin: 0}}>{model.description}</p>
											</Box>			
										</Box>	
						     	   	</Grid>
					        		<Grid item xs={1}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>

						<hr className="spacer"/>

			      		<Grid container style={{paddingTop: "40px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={1}>
						     	   </Grid>
					        		<Grid item xs={4}>
					        			{model.imageDay != "" ? (
					        				<>
												<Box sx={{border: "2px solid #eee", marginTop: "20px",padding: "10px", borderRadius: "0px"}}>
													<ReactCompareSlider
													  ref={{
													    current: '[Circular]'
													  }}
													  boundsPadding={0}
													  itemOne={<ReactCompareSliderImage alt="Image one" src={model.imageDay}/>}
													  itemTwo={<ReactCompareSliderImage alt="Image two" src={model.imageNight} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
													  keyboardIncrement="5%"
													  position={50}
				
													  transition=".75s ease-in-out"
													/>					        		
							        			</Box>
							        			<em>{t("Grundriss Tag / Nacht")}</em>	
						        			</>			        		
						        			) : (<></>)
					        			}					        		
					        					        		
						     	   </Grid>
					        		<Grid item xs={6}>
						  				<Box sx={{paddingTop: "0px"}}> 
											<ul class="check">
											{model.featurepoints.map((point) => (
												<li class="check">{point}</li>	
											))}
											</ul>
					   					</Box>
	
						     	   </Grid>
					        		<Grid item xs={1}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>
			     	   	
			      		<Grid container style={{paddingTop: "40px"}}>
				        		<Grid item xs={12}>
						      		<Grid container spacing={2}>
						        		<Grid item xs={2}>
							     	   </Grid>
						        		<Grid item xs={8}>
											<Box sx={{border: "2px solid black", padding: "10px", borderRadius: "5px"}}>
												<ReactCompareSlider
												  ref={{
												    current: '[Circular]'
												  }}
												  boundsPadding={0}
												  itemOne={<ReactCompareSliderImage alt="Image one" src={model.compareImage1}/>}
												  itemTwo={<ReactCompareSliderImage alt="Image two" src={model.compareImage2} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
												  keyboardIncrement="5%"
												  position={50}
												  style={{
												    height: '60vh',
												    width: '100%'
												  }}
												  transition=".75s ease-in-out"
												/>
											</Box>
							     	   </Grid>
						        		<Grid item xs={2}>
							     	   </Grid>
						     	   </Grid>
					     	   </Grid>
			     	   	</Grid>			     	   	
			     	   				     	   	
			     	   				     	   	
						<Box sx={{display: 'flex', flexDirection: 'row', pt: 2, marginTop: "40px"  }}>
				      		<Grid container style={{paddingTop: "0px"}}>
				        		<Grid item xs={12}>
						      		<Grid container spacing={2}>
						        		<Grid item xs={1}>
							     	   </Grid>
						        		<Grid item xs={10}>

								      		<Grid container style={{paddingTop: "0px"}}>
								        		<Grid item xs={12}>
										      		<Grid container spacing={2}>
										        		<Grid item xs={4}>
										        		
										        		<h3 style={{marginTop: "100px"}} class="highlightText sloganNewPoint">{t("Download")}</h3>
										        		
										        		
										        		
											     	   	{getCar(model.url).downloads.map((file) => (

										        			<Paper sx={{marginBottom: "10px"}}>

																<Card sx={{ minWidth: "90%",  border: "none", boxShadow: "none"}} className="centerCarCard">
																	<Box sx={{width: "40px", height: "40px", float: "right"}}>
																		<PictureAsPdfIcon sx={{width: "2em", height: "2em"}}/>
																	</Box>
													
													      			<CardHeader titleTypographyProps={{variant:'h6' }}
													        					title={file.label}
																				subheader=""
													      			/>
													      			<CardContent>
																        <Typography variant="body2" color="text.secondary">
																			
																        </Typography>
																	</CardContent>
																	<CardActions sx={{  alignSelf: "stretch",
																					      display: "flex",
																					      justifyContent: "flex-end",
																					      alignItems: "flex-start",
																					      p: 0,
																	}}>
																		<Button variant="contained"
																				target="new" 
																				href={file.file}
																				sx={{float: "right"}}
																				endIcon={<DownloadIcon />}>
																		  {t("Download")}
																		</Button>
																	</CardActions>
													    		</Card>

										        			</Paper>
														))}


											     	   </Grid>
										        		<Grid item xs={8}>
												      		<Grid container style={{paddingTop: "0px"}}>
												        		<Grid item xs={12}>
														      		<Grid container spacing={2}>
														        		<Grid item xs={6}>														        			

																			<Card sx={{ minWidth: "90%",  border: "none", boxShadow: "none"}} className="centerCarCard">
																
																      			<CardHeader titleTypographyProps={{variant:'h6' }}
																        					title={t("DoNextSteps")}
																							subheader=""
																      			/>
																				<CardActions sx={{  alignSelf: "stretch",
																								      display: "flex",
																								      justifyContent: "flex-end",
																								      alignItems: "flex-start",
																								      p: 0,
																				}}>
																					<SnowshoeingIcon sx={{width: "6em", height: "6em"}}/>
																				</CardActions>
																    		</Card>


												     	   				</Grid>
														        	<Grid item xs={6}>
													        			<Paper sx={{marginBottom: "10px"}}>
													        				<ActionCard usp="1" header={t("InfoMaterialanfordern")} description={t("InfoMaterialanforderDescription")}  actionurl={"requestMaterial"} car={model.url}/>					        				
													        			</Paper>
				
													        			<Paper sx={{marginBottom: "10px"}}>
													        				<ActionCard usp="3" header={t("Terminvereinbaren")} description={t("TerminvereinbarenDescription")} actionurl={"requestDate"}  car={model.url}/>					        				
													        			</Paper>
													        			{model.configurable == "true" ? (			
														        			<Paper>
														        				<ActionCard usp="4" header={t("konfigurieren")} description={t("KonfigureDescription")} actionurl={"/configure/" + model.url}/>					        				
														        			</Paper>
																			) : 
																			(<></>)
																		}														        			
												     	   				</Grid>
											     	   				</Grid>
										     	   				</Grid>
												     	   </Grid>
												     	   </Grid>
										     	   </Grid>
									     	   </Grid>
								     	   	</Grid>	


							     	   	</Grid>		
						     	   	</Grid>		
					     	   	</Grid>						
				     	   	</Grid>	
			     	   	</Box>	
			     	   	
		  				<Box sx={{paddingTop: "40px"}}> 
							<ContactPersons profil={props.props.profil}/>
						</Box>

					</Box>
  				

   			</Box>
   		</>
   	)
}

export default function ModelPage(props) {
  return <ModelContent props={props}/>;
}