import * as React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DropzoneArea } from "mui-file-dropzone";
import Grid from '@mui/material/Grid';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {postFile, formatBytes} from "../../../helper/utils";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function FileUpload(props) {
	console.log(props);
	return (
		<>
		<Grid container style={{paddingBottom: "10px"}}>
			<Grid item xs={10}>

				<TextField 	id="outlined-basic" 
							label={props.label} 
							defaultValue={props.value} 
							variant="outlined" 
							multiline
							sx={{minWidth:"90%", marginBottom: "10px"}}/>


				{props.currentImages.map(image  => (
					<>
						{image.name}<br/>
					</>				
				))}
				
   			</Grid>		
				
			<Grid item xs={2}>
				<InsertPhotoIcon />	

   			</Grid>		
		</Grid>		
	
		
		
		</>	
	);
}



export default function FileUploadDialog(props) {

	const [use, setUse] = React.useState("global");
	const [files, setFiles] = React.useState([]);
  	const [openBD, setOpenBD] = React.useState(false);


	const handleClose = () => {
		props.handleClose(false);
	};

  	const handleChange = () => {
    	props.handleClose(false);
	};

  	const handleDragOver = (e) => {		  
	    console.log("handleDragOver");		  		  
	};

  	const handleDrop = (e) => {		  
	    console.log("handleDrop");		  		  
	};

  	const setLocalFiles = (f) => {		  
	    setFiles(f);
	    console.log(f);
	};

	const handleChangeUse = (e) => {
		setUse(e.target.value);
	};
	
	async function handleUpload() {
	    setOpenBD(true);
	    const formData = new FormData();
	    for(let i = 0; i < files.length; i++) {
		    formData.append('files',files[i]);
		    console.log(files[i]);
				
		}	    
	    formData.append('use',use);
	    if(props.tabId == "1")
		    formData.append('type', "pdf");
	    else
		    formData.append('type', "carimage");
		await postFile(formData);	    
	    setOpenBD(false);
	    setFiles(null);		
	}


 	return (<React.Fragment>
	      		<BootstrapDialog 	onClose={handleClose}
	        						aria-labelledby="customized-dialog-title"
	        						open={props.open}
	        						
	      		>
		        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
		          Fileupload
		        </DialogTitle>
	        		<IconButton aria-label="close"
	          					onClick={handleClose}
						          sx={{
						            position: 'absolute',
						            right: 8,
						            top: 8,
						            color: (theme) => theme.palette.grey[500],
						          }}
	        		>
	 					<CloseIcon />
					</IconButton>
	        	<DialogContent dividers>
					<Box sx={{minHeight: "300px", minWidth: "600px"}}>	
						<center>
							{props.tabId == "1" ? (<></>) : (
								<FormControl sx={{minWidth:"50%", marginBottom: "10px"}}>
								  <InputLabel id="car-select-label">Fahrzeug</InputLabel>
								  <Select	labelId="car-select-label"
								    		id="car-select"
								    		label="Fahrzeug"
								    		value={use}
								    		 onChange={e => handleChangeUse(e)}
								  >
									{props.models.cars.map((car, index) => (
									    <MenuItem value={car.url}>{car.label}</MenuItem>
									))}
								  </Select>
								</FormControl>						
							)}
							<Box sx={{width: "90%", marginLeft: "-0px"}}>	
								<DropzoneArea 	onChange={(files) => setLocalFiles(files)}
												acceptedFiles={["image/jpeg", 
																"image/png", 
																"application/pdf"]}
												showFileNames={true}
												
								/>
							</Box>
							<br/>
							<Button variant="contained" onClick={handleUpload}>
	            				Upload
	          				</Button>											
						</center>						
					</Box >	
	        	</DialogContent>
	        <DialogActions>
	          <Button autoFocus onClick={handleClose}>
	            Beenden
	          </Button>
	        </DialogActions>
      	</BootstrapDialog>
    	<Backdrop
	        sx={{ color: '#fff', zIndex: 8000 }}
	        open={openBD}
	      >
	        <CircularProgress color="inherit" />
	    </Backdrop>       
	</React.Fragment>
  );
}