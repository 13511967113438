import React, {Fragment} from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter as Router, Routes, Route, useParams, useLocation, Link, Navigate } from "react-router-dom";
import { useLayoutEffect } from 'react'

import { ThemeProvider, createTheme } from '@mui/material/styles';
import i18n from "./config/i18n";
import Box from '@mui/material/Box';

import Campy from "./campy/Campy";

import Start from "./pages/Start";
import Considerations from "./pages/Considerations";
import ModelDetail from "./pages/ModelDetail";
import ModelFamilie from "./pages/ModelFamilie";
import ExpansionConcept from "./pages/ExpansionConcept";
import Compare from "./pages/Compare";
import RoofPage from "./pages/RoofPage";
import SeatPage from "./pages/SeatPage";
import NotFound from "./pages/NotFound";

import Backend from "./pages/Backend";
import Login from "./pages/Login";

import SpeedAccess from './snippets/SpeedAccess';

import Configure from "./pages/Configure";
import Footer from "./pages/Footer";

import './App.css';
import './Chatbot.css';



const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


const Protected = ({ isAuthenticated, changeAuth, component: Component, ...routeProps }) => {

	if (isAuthenticated === false) {
    	return <Navigate  to="/login" />;
  	}

  	return (
    	<Fragment>
       		<Component changeAuth={changeAuth} {...routeProps} />
    	</Fragment>
  	);

};



export default function App() {
 	const [mode, setMode] = React.useState('light');
 	const [size, setSize] = React.useState(12);
 	const [theme, setTheme] = React.useState("themeDefault");


		
  	const themeDefault = React.useMemo(
	    () =>
	      createTheme({
	        palette: {
	          mode,
	        },
	        typography: {
				fontSize: size,
				fontFamily: "'LatoRegular'"
			},
	      }),
	    [mode],
  	); 
	const colorMode = React.useMemo(
	    () => ({
	      toggleColorMode: () => {
	        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
	      },
	    }),
	    [],
	);	
	
	
  	return (
			<CookiesProvider defaultSetOptions={{ path: '/' }}>
			    <ColorModeContext.Provider value={colorMode}>  	
					<ThemeProvider theme={themeDefault}>
			  			<Router>
			  				<Inner />
    					</Router>
			  			
					</ThemeProvider>
				</ColorModeContext.Provider>
			</CookiesProvider>				
    					
  );
}



function Inner() {
	let query = useQuery();
 	const [profil, setProfil] = React.useState(query.get("profil") ?  query.get("profil") : "reimo");

	const [language, setLanguage] = React.useState(query.get("lng") ?  query.get("lng") : "de");
	const [chatStatus, setChatStatus] = React.useState(false);
	const [embedded, setEmbedded] = React.useState(query.get("embedded") ?  query.get("embedded") : true);
	const [isAuthenticated, setIsAuthenticated] = React.useState(false);

	i18n.changeLanguage(language);

	const ModelDetailWrapper = () => {
  		const { model } = useParams();
  		return <ModelDetail language={language} model={model} embedded={embedded}/>;
	};

	const changeAuth = (value) => {
		setIsAuthenticated(value);
		<Link />
	};

	const ConfigureWrapper = () => {
  		const { model } = useParams();
  		return <Configure language={language} model={model} embedded={embedded}/>;
	};

	const ExpansionConceptWrapper = () => {
  		const { model } = useParams();
  		return <ExpansionConcept language={language}  expansion={model} embedded={embedded}/>;
	};

	const FrontendFooter = () => {
  		return 	<Box>
					<SpeedAccess setChatStatus={setChatStatus} profil={profil}/>
					<Campy expanded={chatStatus} setChatStatus={setChatStatus}/>
					{embedded === true || embedded == "true" ? (<></>) : (<Footer/>)}
																		  				
				</Box>;
	};

  	return (	<Wrapper>
					<Routes>
			  			<Route path="/" element={<>
			  										<Start language={language}  embedded={embedded} profil={profil}/> 
													<FrontendFooter />
			  									</>} />
			  			<Route 	path="/model/:model" 
			  					element={<>
											<ModelDetailWrapper/>
			  								<FrontendFooter/>
			  							</>} 
			  					handle={{ crumb: () => <Link to="/model/:model">ModelDetail</Link> }}
			  			/>
			  			<Route 	path="/configure/:model" 
			  					element={<>
	  										<ConfigureWrapper/>
	  										<FrontendFooter/>
			  					</>} 
			  					handle={{ crumb: () => <Link to="/configure/:model">Konfigurator</Link> }}
			  			/>
			  			<Route 	path="/modelfamilie" 
			  					element={<>	
											<ModelFamilie language={language}  embedded={embedded}/>
											<FrontendFooter/>
			  					</>} 
			  					handle={{ crumb: () => <Link to="/modelfamilie">modelfamilie</Link> }}
			  			/>
			  			<Route 	path="/expansionconcept/:model" 
			  					element={<>
											<ExpansionConceptWrapper/>
											<FrontendFooter/>
			  					</>} 
			  					handle={{ crumb: () => <Link to="/expansionconcept/:model">expansionconcept</Link> }}
			  			/>
			  			<Route 	path="/considerations" 
			  					element={<>
		  									<Considerations language={language}  embedded={embedded}/>
											<FrontendFooter/>
			  					</>} 
			  					handle={{ crumb: () => <Link to="/considerations">considerations</Link> }}			  			
			  			/>
			  			<Route 	path="/compare" 
			  					element={<>
											<Compare language={language} embedded={embedded} />
											<FrontendFooter/>
			  					</>} 
			  			/>
			  			<Route 	path="/roof" 
			  					element={<>
											<RoofPage language={language} embedded={embedded} />
											<FrontendFooter/>
			  					</>} 
			  			/>
			  			<Route 	path="/seat" 
			  					element={<>
											<SeatPage language={language} embedded={embedded} />
											<FrontendFooter/>
			  					</>} 
			  			/>
			  			<Route 	path="/login" 
			  					element={<>
											<Login 	language={language} 
													embedded={embedded} 
													changeAuth={changeAuth}
													isAuthenticated={isAuthenticated} 
											/>
											<FrontendFooter/>
			  					</>} 
			  			/>
						<Route 	exact path='/backend' 
								element={<Protected isAuthenticated={isAuthenticated} 
													changeAuth={changeAuth}
													component={Backend} />} 
						/>

			  			<Route path="*" element={<>
	  												<NotFound  />
	  												<FrontendFooter/>
			  									</>} 
			  			/>
			        </Routes>
		        </Wrapper>
  	);
}

