import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';

import {addNewCar} from "../../../helper/utils";

import defaultCar from '../../../db/defaultCar.json';
import models from '../../../db/models.json';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

export default function NewCar(props) {
  	const [ openBD, setOpenBD] = React.useState(false);
  	const [ newCar, setNewCar] = React.useState(defaultCar);
	
  	const handleCloseBD = () => {
    	setOpenBD(false);
  	};
  	 

	const { t, i18n } = useTranslation("",{lng: props.language});


    function handleClose() {
    	props.handleClose(false);
  	};

    async function handleInsertClose() {
		let newJson = models;
		newJson.cars.push(newCar);		
		await addNewCar(newJson, newCar.url);
    	props.handleClose(false);
  	};

	const handleChange = (e) => {
		newCar.url = e.target.value;
		setNewCar(newCar);
	};


 	  	
  	return (<React.Fragment>
				<BootstrapDialog 	onClose={handleClose}
									aria-labelledby="customized-dialog-title"
 									open={props.open} 									
      			>
	        		<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
	          			Neues Fahrzeug anlegen
	        		</DialogTitle>
			        <IconButton aria-label="close"
			          			onClick={handleClose}
						        sx={{
						            position: 'absolute',
						            right: 8,
						            top: 8,
						            color: (theme) => theme.palette.grey[500],
						        }}
					>
	          			<CloseIcon />
	        		</IconButton>
			        <DialogContent dividers>
						<React.Fragment>
						<TextField 	id="outlined-basic" 
									label="neue Fahrzeug ID" 
									defaultValue={defaultCar.url} 
									onChange={e => handleChange(e)}
									variant="outlined" 
									sx={{minWidth:"90%", marginBottom: "10px"}}
						/>

						</React.Fragment>

			        </DialogContent>
	        		<DialogActions>
						<Button autoFocus 
								variant="contained"
								endIcon={<ClearIcon />}
								onClick={handleClose}
						>
				            {t("Abbruch")}
				        </Button>				        
						<Button autoFocus 
								variant="contained"
								endIcon={<DoneIcon />}
								onClick={handleInsertClose}
						>
				            {t("OK")}
				        </Button>				        
			        </DialogActions>
      			</BootstrapDialog>
		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			        onClick={handleCloseBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>      			
      			
    </React.Fragment>
  );
}